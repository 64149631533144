.credits {
	font-size: 1.2rem;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 20px;
}

.credits span {
	flex: 1;
}

.player, .emptyPlayer {
	display: flex;
	padding: 5px;
}

.emptyPlayer {
	height: 100%;
	justify-content: center
}

.playerImg, .emptyImg {
	max-width: 40%;
	min-width: 40%;
	/*flex: 1;*/
	background-size: contain !important;
}

.playerData {
	list-style: none;
	padding: 0;
}

.playerStatsContainer {
	flex: 1;
}

.bid {
	font-size: 1.5rem;
    display: flex;
    justify-content: center;
    background-color: #F6C634;
    border-radius: 4px;
    margin-top: 1rem;
}

.bidValue {
	font-weight: bold;
	margin-left: 5px;
}

.bidPanel {
	margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.fixedBids {
	display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.fixedBids button {
	min-width: 30%;
	height: 60px;
	font-size: 1.5rem;
}

.passBid {
	margin-bottom: 10px;
}

.passBid button {
	width: 100%;
	height: 60px;
	font-size: 1.5rem;
}

.customBid {
	display: flex;
	flex-direction: row;
}

.customBid button {
	flex: 1;
}

.bidFixedValue {
	width: 30%;
	justify-content: center;
	background-color: rgb(190,190,190);
	display: flex;
	font-weight: bold;
	margin-top: 15px;
}

.otherPlayers {
	display: none;
}

.auctionContainer {
	padding: 2rem;
}

@media only screen and (min-width: 900px) {
	.rowContainer {
		display: flex;
		flex-direction: row;
	}

	.myTeamModal {
		width: 380px;
		margin-top: 1rem;
		padding: 2rem;
	}

	.auctionContainer {
		min-width: 50%;
		margin-top: 1rem;
		padding: 2rem;
	}

	.otherPlayers {
		display: none;
	}

	.logoBoxContainer {
		flex: 1;
		max-width: 50%;
	}
}

.statsContainer {
	margin-top: 1rem;
}
