.fullWidthLogoContainer {
	width: 100vw;
	height: 100vh;
	justify-content: center;
	flex-direction: column;
	display: flex;
	align-items: center;
	/*margin: auto*/
}

@media only screen and (max-width: 900px) {

	.logoContainer {
		width: 90vw;
		height: 90vh;
		justify-content: center;
		flex-direction: column;
		display: flex;
		align-items: center;
		margin: auto;
	}
}

@media only screen and (min-width: 900px) {
	.logoContainer {
		/*width: 50%;*/
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	    margin: 5rem;
	}
}
